// test work
console.log("js is loaded.")

import {_alert} from './tools/alert'

_alert("hello world")


// acios setup
import axios from 'axios'
window.axios = axios


import 'virtual:uno.css'